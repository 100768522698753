export default [
    {
        path: '/idea/category/list',
        name: 'idea-category-list',
        component: () => import('@/views/idea/category/List.vue'),
        meta: {
            pageTitle: 'Geri Bildirim Kategorileri'
        },
    },
    {
        path: '/idea/category/add/',
        name: 'idea-category-add',
        component: () => import('@/views/idea/category/Detail.vue'),
        meta: {
            pageTitle: 'Geri Bildirim Kategori Ekle',
            breadcrumb: [
                {
                    text: 'Geri Bildirim Kategorileri',
                    to: {name: 'idea-category-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/idea/category/edit/:id',
        name: 'idea-category-edit',
        component: () => import('@/views/idea/category/Detail.vue'),
        meta: {
            pageTitle: 'Geri Bildirim Kategori Değiştir',
            breadcrumb: [
                {
                    text: 'Geri Bildirim Kategorileri',
                    to: {name: 'idea-category-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/idea/list',
        name: 'idea-list',
        component: () => import('@/views/idea/idea/List.vue'),
        meta: {
            pageTitle: 'Geri Bildirim Masası'
        },
    },
    {
        path: '/idea/add/',
        name: 'idea-add',
        component: () => import('@/views/idea/idea/Detail.vue'),
        meta: {
            pageTitle: 'Geri Bildirim Ekle',
            breadcrumb: [
                {
                    text: 'Geri Bildirim Masası',
                    to: {name: 'idea-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/idea/edit/:id',
        name: 'idea-edit',
        component: () => import('@/views/idea/idea/Detail.vue'),
        meta: {
            pageTitle: 'Geri Bildirim Değiştir',
            breadcrumb: [
                {
                    text: 'Geri Bildirim Masası',
                    to: {name: 'idea-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    }
]