export const $themeColors = {}
export const $themeBreakpoints = {}
export const $themeConfig = {
    app: {
        appName: 'Mustersoft',
        appLogoImage: require('@/assets/images/logo/logo.png'),
        appLogoImageLight: require('@/assets/images/logo/logo_light.png'),
        appApiUrl: 'https://portal-api.mustersoft.com/api', //'https://localhost:7214/api', //
        appIsDebug: false,
        appIsDemo: false
    },
    layout: {
        isRTL: false,
        skin: 'semi-dark', // light, dark, bordered, semi-dark
        routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
        type: 'vertical', // vertical, horizontal
        contentWidth: 'full', // full, boxed
        menu: {
            hidden: false,
            isCollapsed: false,
        },
        navbar: {
            type: 'sticky', // static , sticky , floating, hidden
            backgroundColor: '', // BS color options [primary, success, etc]
        },
        footer: {
            type: 'hidden', // static, sticky, hidden
        },
        customizer: true,
        enableScrollToTop: true,
    },
}
