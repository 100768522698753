export default [
    {
        path: '/organization/corporation/list',
        name: 'organization-corporation-list',
        component: () => import('@/views/organization/corporation/List.vue'),
        meta: {
            pageTitle: 'Şirket Tanımları'
        },
    },
    {
        path: '/organization/corporation/add/',
        name: 'organization-corporation-add',
        component: () => import('@/views/organization/corporation/Detail.vue'),
        meta: {
            pageTitle: 'Şirket Ekle',
            breadcrumb: [
                {
                    text: 'Şirket Tanımları',
                    to: {name: 'organization-corporation-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/corporation/edit/:id',
        name: 'organization-corporation-edit',
        component: () => import('@/views/organization/corporation/Detail.vue'),
        meta: {
            pageTitle: 'Şirket Değiştir',
            breadcrumb: [
                {
                    text: 'Şirket Tanımları',
                    to: {name: 'organization-corporation-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/workplace/list',
        name: 'organization-workplace-list',
        component: () => import('@/views/organization/workplace/List.vue'),
        meta: {
            pageTitle: 'İşyeri Tanımları'
        },
    },
    {
        path: '/organization/workplace/add/',
        name: 'organization-workplace-add',
        component: () => import('@/views/organization/workplace/Detail.vue'),
        meta: {
            pageTitle: 'İşyeri Ekle',
            breadcrumb: [
                {
                    text: 'İşyeri Tanımları',
                    to: {name: 'organization-workplace-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/workplace/edit/:id',
        name: 'organization-workplace-edit',
        component: () => import('@/views/organization/workplace/Detail.vue'),
        meta: {
            pageTitle: 'İşyeri Değiştir',
            breadcrumb: [
                {
                    text: 'İşyeri Tanımları',
                    to: {name: 'organization-workplace-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/organization/list',
        name: 'organization-organization-list',
        component: () => import('@/views/organization/organization/List.vue'),
        meta: {
            pageTitle: 'Organizasyon Tanımları'
        },
    },
    {
        path: '/organization/organization/add/',
        name: 'organization-organization-add',
        component: () => import('@/views/organization/organization/Detail.vue'),
        meta: {
            pageTitle: 'Organizasyon Ekle',
            breadcrumb: [
                {
                    text: 'Organizasyon Tanımları',
                    to: {name: 'organization-organization-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/organization/edit/:id',
        name: 'organization-organization-edit',
        component: () => import('@/views/organization/organization/Detail.vue'),
        meta: {
            pageTitle: 'Organizasyon Değiştir',
            breadcrumb: [
                {
                    text: 'Organizasyon Tanımları',
                    to: {name: 'organization-organization-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/position/list',
        name: 'organization-position-list',
        component: () => import('@/views/organization/position/List.vue'),
        meta: {
            pageTitle: 'Pozisyon Tanımları'
        },
    },
    {
        path: '/organization/position/add/',
        name: 'organization-position-add',
        component: () => import('@/views/organization/position/Detail.vue'),
        meta: {
            pageTitle: 'Pozisyon Ekle',
            breadcrumb: [
                {
                    text: 'Pozisyon Tanımları',
                    to: {name: 'organization-position-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/position/edit/:id',
        name: 'organization-position-edit',
        component: () => import('@/views/organization/position/Detail.vue'),
        meta: {
            pageTitle: 'Pozisyon Değiştir',
            breadcrumb: [
                {
                    text: 'Pozisyon Tanımları',
                    to: {name: 'organization-position-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/employee/list',
        name: 'organization-employee-list',
        component: () => import('@/views/organization/employee/List.vue'),
        meta: {
            pageTitle: 'Çalışan Yönetimi'
        },
    },
    {
        path: '/organization/employee/add/',
        name: 'organization-employee-add',
        component: () => import('@/views/organization/employee/Detail.vue'),
        meta: {
            pageTitle: 'Çalışan Ekle',
            breadcrumb: [
                {
                    text: 'Çalışan Yönetimi',
                    to: {name: 'organization-employee-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organization/employee/edit/:id',
        name: 'organization-employee-edit',
        component: () => import('@/views/organization/employee/Detail.vue'),
        meta: {
            pageTitle: 'Çalışan Değiştir',
            breadcrumb: [
                {
                    text: 'Çalışan Yönetimi',
                    to: {name: 'organization-employee-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]
