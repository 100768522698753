export default [
    {
        path: '/employee/list',
        name: 'employee-list',
        component: () => import('@/views/employee/List.vue'),
        meta: {
            pageTitle: 'Çalışan Listesi'
        },
    },
    {
        path: '/employee/scheme',
        name: 'employee-scheme',
        component: () => import('@/views/employee/scheme/Index.vue'),
        meta: {
            pageTitle: 'Organizasyon Şeması'
        },
    },
]
