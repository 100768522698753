export default [
    {
        path: '/asset/category/list',
        name: 'asset-category-list',
        component: () => import('@/views/asset/category/List.vue'),
        meta: {
            pageTitle: 'Demirbaş Kategorileri'
        },
    },
    {
        path: '/asset/category/add/',
        name: 'asset-category-add',
        component: () => import('@/views/asset/category/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Kategori Ekle',
            breadcrumb: [
                {
                    text: 'Demirbaş Kategorileri',
                    to: {name: 'asset-category-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/category/edit/:id',
        name: 'asset-category-edit',
        component: () => import('@/views/asset/category/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Kategori Değiştir',
            breadcrumb: [
                {
                    text: 'Demirbaş Kategorileri',
                    to: {name: 'asset-category-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/brand/list',
        name: 'asset-brand-list',
        component: () => import('@/views/asset/brand/List.vue'),
        meta: {
            pageTitle: 'Demirbaş Markaları'
        },
    },
    {
        path: '/asset/brand/add/',
        name: 'asset-brand-add',
        component: () => import('@/views/asset/brand/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Marka Ekle',
            breadcrumb: [
                {
                    text: 'Demirbaş Markaları',
                    to: {name: 'asset-brand-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/brand/edit/:id',
        name: 'asset-brand-edit',
        component: () => import('@/views/asset/brand/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Marka Değiştir',
            breadcrumb: [
                {
                    text: 'Demirbaş Markaları',
                    to: {name: 'asset-brand-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/supplier/list',
        name: 'asset-supplier-list',
        component: () => import('@/views/asset/supplier/List.vue'),
        meta: {
            pageTitle: 'Demirbaş Tedarikçileri'
        },
    },
    {
        path: '/asset/supplier/add/',
        name: 'asset-supplier-add',
        component: () => import('@/views/asset/supplier/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Tedarikçi Ekle',
            breadcrumb: [
                {
                    text: 'Demirbaş Tedarikçileri',
                    to: {name: 'asset-supplier-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/supplier/edit/:id',
        name: 'asset-supplier-edit',
        component: () => import('@/views/asset/supplier/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Tedarikçi Değiştir',
            breadcrumb: [
                {
                    text: 'Demirbaş Tedarikçileri',
                    to: {name: 'asset-supplier-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/add/',
        name: 'asset-add',
        component: () => import('@/views/asset/asset/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Ekle',
            breadcrumb: [
                {
                    text: 'Demirbaş Listesi',
                    to: {name: 'asset-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/edit/:id',
        name: 'asset-edit',
        component: () => import('@/views/asset/asset/Detail.vue'),
        meta: {
            pageTitle: 'Demirbaş Değiştir',
            breadcrumb: [
                {
                    text: 'Demirbaş Listesi',
                    to: {name: 'asset-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/asset/asset/list',
        name: 'asset-list',
        component: () => import('@/views/asset/asset/List.vue'),
        meta: {
            pageTitle: 'Demirbaş Listesi'
        },
    },
    {
        path: '/asset/asset/search/:id',
        name: 'asset-search',
        component: () => import('@/views/asset/asset/Search.vue'),
        meta: {
            pageTitle: 'Demirbaş Sorgulama',
            breadcrumb: [
                {
                    text: 'Demirbaş Listesi',
                    to: {name: 'asset-list'}
                },
                {
                    text: 'Sorgulama',
                    active: true,
                },
            ],
        },
    },
]