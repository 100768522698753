export default [
    {
        path: '/announcement/category/list',
        name: 'announcement-category-list',
        component: () => import('@/views/announcement/category/List.vue'),
        meta: {
            pageTitle: 'Duyuru Kategorileri'
        },
    },
    {
        path: '/announcement/category/add/',
        name: 'announcement-category-add',
        component: () => import('@/views/announcement/category/Detail.vue'),
        meta: {
            pageTitle: 'Duyuru Kategori Ekle',
            breadcrumb: [
                {
                    text: 'Duyuru Kategorileri',
                    to: {name: 'announcement-category-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/announcement/category/edit/:id',
        name: 'announcement-category-edit',
        component: () => import('@/views/announcement/category/Detail.vue'),
        meta: {
            pageTitle: 'Duyuru Kategori Değiştir',
            breadcrumb: [
                {
                    text: 'Duyuru Kategorileri',
                    to: {name: 'announcement-category-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/announcement/management',
        name: 'announcement-management',
        component: () => import('@/views/announcement/announcement/Management.vue'),
        meta: {
            pageTitle: 'Duyuru Yönetimi'
        },
    },
    {
        path: '/announcement/add/',
        name: 'announcement-add',
        component: () => import('@/views/announcement/announcement/Detail.vue'),
        meta: {
            pageTitle: 'Duyuru Ekle',
            breadcrumb: [
                {
                    text: 'Duyuru Yönetimi',
                    to: {name: 'announcement-management'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/announcement/edit/:id',
        name: 'announcement-edit',
        component: () => import('@/views/announcement/announcement/Detail.vue'),
        meta: {
            pageTitle: 'Duyuru Değiştir',
            breadcrumb: [
                {
                    text: 'Duyuru Yönetimi',
                    to: {name: 'announcement-management'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/announcement/list',
        name: 'announcement-list',
        component: () => import('@/views/announcement/List.vue'),
        meta: {
            pageTitle: 'Duyuru Listesi'
        },
    },
]