export default [
    {
        path: '/form/waiting/list',
        name: 'form-waiting-list',
        component: () => import('@/views/form/waiting/List.vue'),
        meta: {
            pageTitle: 'Onay Bekleyenler'
        },
    },
    {
        path: '/form/leave/list',
        name: 'form-leave-list',
        component: () => import('@/views/form/leave/List.vue'),
        meta: {
            pageTitle: 'İzin Formları'
        },
    },
    {
        path: '/form/leave/add/',
        name: 'form-leave-add',
        component: () => import('@/views/form/leave/Detail.vue'),
        meta: {
            pageTitle: 'İzin Formu Ekle',
            breadcrumb: [
                {
                    text: 'İzin Formları',
                    to: {name: 'form-leave-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/leave/edit/:id',
        name: 'form-leave-edit',
        component: () => import('@/views/form/leave/Detail.vue'),
        meta: {
            pageTitle: 'İzin Formu Değiştir',
            breadcrumb: [
                {
                    text: 'İzin Formları',
                    to: {name: 'form-leave-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/advance/list',
        name: 'form-advance-list',
        component: () => import('@/views/form/advance/List.vue'),
        meta: {
            pageTitle: 'Avans Formları'
        },
    },
    {
        path: '/form/advance/add/',
        name: 'form-advance-add',
        component: () => import('@/views/form/advance/Detail.vue'),
        meta: {
            pageTitle: 'Avans Formu Ekle',
            breadcrumb: [
                {
                    text: 'Avans Formları',
                    to: {name: 'form-advance-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/advance/edit/:id',
        name: 'form-advance-edit',
        component: () => import('@/views/form/advance/Detail.vue'),
        meta: {
            pageTitle: 'Avans Formu Değiştir',
            breadcrumb: [
                {
                    text: 'Avans Formları',
                    to: {name: 'form-advance-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/expense/list',
        name: 'form-expense-list',
        component: () => import('@/views/form/expense/List.vue'),
        meta: {
            pageTitle: 'Masraf Formları'
        },
    },
    {
        path: '/form/expense/add/',
        name: 'form-expense-add',
        component: () => import('@/views/form/expense/Detail.vue'),
        meta: {
            pageTitle: 'Masraf Formu Ekle',
            breadcrumb: [
                {
                    text: 'Masraf Formları',
                    to: {name: 'form-expense-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/expense/edit/:id',
        name: 'form-expense-edit',
        component: () => import('@/views/form/expense/Detail.vue'),
        meta: {
            pageTitle: 'Masraf Formu Değiştir',
            breadcrumb: [
                {
                    text: 'Masraf Formları',
                    to: {name: 'form-expense-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/overwork/list',
        name: 'form-overwork-list',
        component: () => import('@/views/form/overwork/List.vue'),
        meta: {
            pageTitle: 'Fazla Mesai Formları'
        },
    },
    {
        path: '/form/overwork/add/',
        name: 'form-overwork-add',
        component: () => import('@/views/form/overwork/Detail.vue'),
        meta: {
            pageTitle: 'Fazla Mesai Formu Ekle',
            breadcrumb: [
                {
                    text: 'Fazla Mesai Formları',
                    to: {name: 'form-overwork-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/form/overwork/edit/:id',
        name: 'form-overwork-edit',
        component: () => import('@/views/form/overwork/Detail.vue'),
        meta: {
            pageTitle: 'Fazla Mesai Formu Değiştir',
            breadcrumb: [
                {
                    text: 'Fazla Mesai Formları',
                    to: {name: 'form-overwork-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]