export default [
    {
        path: '/meeting/category/list',
        name: 'meeting-category-list',
        component: () => import('@/views/meeting/category/List.vue'),
        meta: {
            pageTitle: 'Toplantı Kategorileri'
        },
    },
    {
        path: '/meeting/category/add/',
        name: 'meeting-category-add',
        component: () => import('@/views/meeting/category/Detail.vue'),
        meta: {
            pageTitle: 'Toplantı Kategori Ekle',
            breadcrumb: [
                {
                    text: 'Toplantı Kategorileri',
                    to: {name: 'meeting-category-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/meeting/category/edit/:id',
        name: 'meeting-category-edit',
        component: () => import('@/views/meeting/category/Detail.vue'),
        meta: {
            pageTitle: 'Toplantı Kategori Değiştir',
            breadcrumb: [
                {
                    text: 'Toplantı Kategorileri',
                    to: {name: 'meeting-category-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/meeting/room/list',
        name: 'meeting-room-list',
        component: () => import('@/views/meeting/room/List.vue'),
        meta: {
            pageTitle: 'Toplantı Salonları'
        },
    },
    {
        path: '/meeting/room/add/',
        name: 'meeting-room-add',
        component: () => import('@/views/meeting/room/Detail.vue'),
        meta: {
            pageTitle: 'Toplantı Salonu Ekle',
            breadcrumb: [
                {
                    text: 'Toplantı Salonları',
                    to: {name: 'meeting-room-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/meeting/room/edit/:id',
        name: 'meeting-room-edit',
        component: () => import('@/views/meeting/room/Detail.vue'),
        meta: {
            pageTitle: 'Toplantı Salonu Değiştir',
            breadcrumb: [
                {
                    text: 'Toplantı Salonları',
                    to: {name: 'meeting-room-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/meeting/list',
        name: 'meeting-list',
        component: () => import('@/views/meeting/meeting/List.vue'),
        meta: {
            pageTitle: 'Toplantı Yönetimi'
        },
    },
    {
        path: '/meeting/add/',
        name: 'meeting-add',
        component: () => import('@/views/meeting/meeting/Detail.vue'),
        meta: {
            pageTitle: 'Toplantı Ekle',
            breadcrumb: [
                {
                    text: 'Toplantı Yönetimi',
                    to: {name: 'meeting-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/meeting/edit/:id',
        name: 'meeting-edit',
        component: () => import('@/views/meeting/meeting/Detail.vue'),
        meta: {
            pageTitle: 'Toplantı Değiştir',
            breadcrumb: [
                {
                    text: 'Toplantı Yönetimi',
                    to: {name: 'meeting-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/meeting/report/:id',
        name: 'meeting-report',
        component: () => import('@/views/meeting/meeting/Report.vue'),
        meta: {
            pageTitle: 'Toplantı Tutanak Bilgileri',
            breadcrumb: [
                {
                    text: 'Toplantı Yönetimi',
                    to: {name: 'meeting-list'}
                },
                {
                    text: 'Tutanak',
                    active: true,
                },
            ],
        },
    }
]
