export default [
    {
        path: '/task',
        name: 'task-dashboard',
        component: () => import('@/views/task/Dashboard.vue'),
        meta: {
            pageTitle: 'Destek Merkezi'
        },
    },
    {
        path: '/task/list/:recordType/:statusType',
        name: 'task-list',
        component: () => import('@/views/task/List.vue'),
        meta: {
            pageTitle: 'Görev Listesi',
            breadcrumb: [
                {
                    text: 'Destek Merkezi',
                    to: {name: 'task-dashboard'}
                }
            ],
        },
    },
    {
        path: '/task/detail/:recordType/:id',
        name: 'task-detail',
        component: () => import('@/views/task/Detail.vue'),
        meta: {
            pageTitle: 'Destek Kaydı Bilgileri',
            breadcrumb: [
                {
                    text: 'Destek Merkezi',
                    to: {name: 'task-dashboard'}
                },
                {
                    text: 'Görev Listesi',
                },
                {
                    text: 'Destek Kaydı',
                    active: true,
                },
            ],
        },
    },
]