export default [
    {
        path: '/access/list',
        name: 'definition-access-list',
        component: () => import('@/views/definition/access/List.vue'),
        meta: {
            pageTitle: 'Erişim Tanımları'
        },
    },
    {
        path: '/access/add/',
        name: 'definition-access-add',
        component: () => import('@/views/definition/access/Detail.vue'),
        meta: {
            pageTitle: 'Erişim Ekle',
            breadcrumb: [
                {
                    text: 'Erişim Tanımları',
                    to: {name: 'definition-access-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/access/edit/:id',
        name: 'definition-access-edit',
        component: () => import('@/views/definition/access/Detail.vue'),
        meta: {
            pageTitle: 'Erişim Değiştir',
            breadcrumb: [
                {
                    text: 'Erişim Tanımları',
                    to: {name: 'definition-access-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/workflow/list',
        name: 'definition-workflow-list',
        component: () => import('@/views/definition/workflow/List.vue'),
        meta: {
            pageTitle: 'Onay Süreci Tanımları'
        },
    },
    {
        path: '/workflow/add/',
        name: 'definition-workflow-add',
        component: () => import('@/views/definition/workflow/Detail.vue'),
        meta: {
            pageTitle: 'Onay Süreci Ekle',
            breadcrumb: [
                {
                    text: 'Onay Süreci Tanımları',
                    to: {name: 'definition-workflow-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/workflow/edit/:id',
        name: 'definition-workflow-edit',
        component: () => import('@/views/definition/workflow/Detail.vue'),
        meta: {
            pageTitle: 'Onay Süreci Değiştir',
            breadcrumb: [
                {
                    text: 'Onay Süreci Tanımları',
                    to: {name: 'definition-workflow-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/leavetype/list',
        name: 'definition-leavetype-list',
        component: () => import('@/views/definition/leavetype/List.vue'),
        meta: {
            pageTitle: 'İzin Parametreleri'
        },
    },
    {
        path: '/leavetype/add/',
        name: 'definition-leavetype-add',
        component: () => import('@/views/definition/leavetype/Detail.vue'),
        meta: {
            pageTitle: 'İzin Türü Ekle',
            breadcrumb: [
                {
                    text: 'İzin Parametreleri',
                    to: {name: 'definition-leavetype-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/leavetype/edit/:id',
        name: 'definition-leavetype-edit',
        component: () => import('@/views/definition/leavetype/Detail.vue'),
        meta: {
            pageTitle: 'İzin Türü Değiştir',
            breadcrumb: [
                {
                    text: 'İzin Parametreleri',
                    to: {name: 'definition-leavetype-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/officialday/list',
        name: 'definition-officialday-list',
        component: () => import('@/views/definition/officialday/List.vue'),
        meta: {
            pageTitle: 'Resmi Tatiller'
        },
    },
    {
        path: '/officialday/add/',
        name: 'definition-officialday-add',
        component: () => import('@/views/definition/officialday/Detail.vue'),
        meta: {
            pageTitle: 'Resmi Tatil Ekle',
            breadcrumb: [
                {
                    text: 'Resmi Tatiller',
                    to: {name: 'definition-officialday-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/officialday/edit/:id',
        name: 'definition-officialday-edit',
        component: () => import('@/views/definition/officialday/Detail.vue'),
        meta: {
            pageTitle: 'Resmi Tatil Değiştir',
            breadcrumb: [
                {
                    text: 'Resmi Tatiller',
                    to: {name: 'definition-officialday-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/task-category/list',
        name: 'definition-task-category-list',
        component: () => import('@/views/definition/taskcategory/List.vue'),
        meta: {
            pageTitle: 'Görev Kategorileri'
        },
    },
    {
        path: '/task-category/add/',
        name: 'definition-task-category-add',
        component: () => import('@/views/definition/taskcategory/Detail.vue'),
        meta: {
            pageTitle: 'Görev Kategori Ekle',
            breadcrumb: [
                {
                    text: 'Görev Kategorileri',
                    to: {name: 'definition-task-category-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/task-category/edit/:id',
        name: 'definition-task-category-edit',
        component: () => import('@/views/definition/taskcategory/Detail.vue'),
        meta: {
            pageTitle: 'Görev Kategori Değiştir',
            breadcrumb: [
                {
                    text: 'Görev Kategorileri',
                    to: {name: 'definition-task-category-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/committee/list',
        name: 'definition-committee-list',
        component: () => import('@/views/definition/committee/List.vue'),
        meta: {
            pageTitle: 'Komite Tanımları'
        },
    },
    {
        path: '/committee/add/',
        name: 'definition-committee-add',
        component: () => import('@/views/definition/committee/Detail.vue'),
        meta: {
            pageTitle: 'Komite Ekle',
            breadcrumb: [
                {
                    text: 'Komite Tanımları',
                    to: {name: 'definition-committee-list'}
                },
                {
                    text: 'Ekle',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/committee/edit/:id',
        name: 'definition-committee-edit',
        component: () => import('@/views/definition/committee/Detail.vue'),
        meta: {
            pageTitle: 'Komite Değiştir',
            breadcrumb: [
                {
                    text: 'Komite Tanımları',
                    to: {name: 'definition-committee-list'}
                },
                {
                    text: 'Değiştir',
                    active: true,
                },
            ],
        },
    },
]
